import React, { createRef } from "react";
import Header from "~/components/aboutHeader";
import Banner from "~/components/aboutBanner";
import Partners from "~/components/aboutPartners";
import Methodology from "~/components/aboutMethodology";
import Layout from "~/components/layout";

const IndexPage = ({ location }) => {
	const header = createRef();
	const banner = createRef();
	const methodology = createRef();
	const partners = createRef();

	return (
		<Layout data-location={location} pageTitle={'About - Ideas Report Hub | WeTransfer'}>
			<Header ref={header} />
			<Methodology ref={methodology} />
			<Banner ref={banner} />
			<Partners ref={partners} />
		</Layout>
	);
};

export default IndexPage;
